<template>
  <div>
    <div v-if="loaded && done === totalPromises">
      <div class="print-overlay" ref="print">
        <div class="container">
          <div style="max-width: 100%; width: 1000px;" class="has-text-right mb-5" id="print">
            <b-button type="is-primary is-gradient" icon-left="printer" outlined @click="print">
              Print
            </b-button>
          </div>
        </div>
      </div>

      <div class="container report">
        <header class="is-flex is-justify-content-space-between is-align-items-center mb-5">
          <div>
            <img src="/img/assets/Sgara-Logo.png" alt="Sgara Logo" style="height: 45px">
            <p class="mb-0">Periode {{ $dateFormat(from) }} - {{ $dateFormat(to) }}</p>
          </div>
          <div class="has-text-right">
            <p class="is-size-5 mb-0">Laporan Finansial</p>
            <p class="is-size-4">{{ account.name }} - {{ farm_name }}</p>
          </div>
        </header>

        <div class="columns">
          <div class="column is-half">
            <div class="columns">
              <div class="column is-half">
                <div class="card is-shadowless is-bordered">
                  <div class="card-content has-text-centered">
                    <p class="has-text-weight-bold mb-1">Cashflow</p>
                    <span class="has-text-weight-bold" :class="{
                    'has-text-success': total.cashflow >= 0,
                    'has-text-danger': total.cashflow < 0,
                  }">{{ $currency(total.cashflow) }}</span>
                  </div>
                </div>
              </div>
              <div class="column is-half">
                <div class="card is-shadowless is-bordered">
                  <div class="card-content has-text-centered">
                    <p class="has-text-weight-bold mb-1">Harga Pokok Penjualan (HPP)</p>
                    <span class="has-text-weight-bold has-text-info">{{ $currency(hpp.hpp) }} / Kg</span>
                  </div>
                </div>
              </div>
              <div class="column is-half">
                <div
                    class="card is-shadowless is-bordered h-100 is-flex is-justify-content-center is-align-items-center">
                  <div class="card-content has-text-centered">
                    <p class="has-text-weight-bold mb-1">Pengeluaran Terbesar</p>
                    <span class="has-text-weight-bold has-text-danger">{{ biggestOutcome }}</span>
                  </div>
                </div>
              </div>
              <div class="column is-half">
                <div class="card is-shadowless is-bordered">
                  <div class="card-content has-text-centered">
                    <p class="has-text-weight-bold mb-1">Pemasukan Terbesar</p>
                    <p class="has-text-weight-bold has-text-success">{{ biggestIncome }}</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="column is-half">
            <b-table :mobile-cards="false" :data="group_transaction.in"
                     default-sort-direction="desc"
                     default-sort="total">
              <template #empty>
                <p class="has-text-centered">Tidak ada Data</p>
              </template>

              <b-table-column field="pond_name" label="Kategori" v-slot="props">
              <span :class="{'has-text-weight-bold': props.row.category_transaction_name === 'Total'}">{{
                  props.row.category_transaction_name
                }}</span>
              </b-table-column>
              <b-table-column field="total" label="Pemasukan" v-slot="props" width="200">
              <span :class="{'has-text-weight-bold': props.row.category_transaction_name === 'Total'}"
                    class="has-text-success">{{ $currency(props.row.nominal) }}</span>
              </b-table-column>
            </b-table>
            <b-table :mobile-cards="false" :data="group_transaction.out"
                     default-sort-direction="desc"
                     default-sort="total">
              <template #empty>
                <p class="has-text-centered">Tidak ada Data</p>
              </template>

              <b-table-column field="category" label="Kategori" v-slot="props">
              <span :class="{'has-text-weight-bold': props.row.category_transaction_name === 'Total'}">{{
                  props.row.category_transaction_name
                }}</span>
              </b-table-column>
              <b-table-column field="total" label="Pengeluaran" v-slot="props" width="200">
              <span :class="{'has-text-weight-bold': props.row.category_transaction_name === 'Total'}"
                    class="has-text-danger">{{ $currency(props.row.nominal) }}</span>
              </b-table-column>
            </b-table>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="card is-shadowless is-bordered">
              <div class="card-content">
                <p class="has-text-weight-bold">Pemasukan</p>

                <div v-if="incomeSeries.length">
                  <apexchart type="donut"
                             height="350"
                             :options="incomeOptions"
                             :series="incomeSeries"/>
                </div>
                <div v-else class="mt-2">Tidak ada Data</div>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="card is-shadowless is-bordered">
              <div class="card-content">
                <p class="has-text-weight-bold">Pengeluaran</p>
                <div v-if="outcomeSeries.length">
                  <apexchart type="donut"
                             height="350"
                             :options="outcomeOptions"
                             :series="outcomeSeries"/>
                </div>
                <div v-else class="mt-2">Tidak ada Data</div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-4">
          <p class="has-text-weight-bold mb-2">Pemasukan</p>
          <div class="b-table is-size-7" v-if="arusKas.pemasukan.length">
            <div class="table-wrapper has-mobile-cards">
              <table class="table">
                <thead>
                <tr>
                  <th>
                    <div class="th-wrap"><span class="is-relative"> Kategori </span>
                    </div>
                  </th>
                  <th v-for="(month, key) in arusKas.pemasukanMonths" :key="key" style="width: 200px;">
                    <div class="th-wrap"><span class="is-relative"> {{ $dateFormat(month, 'MMM YYYY') }} </span></div>
                  </th>
                  <th v-if="arusKas.pemasukanMonths.length > 1">
                    <div class="th-wrap"><span class="is-relative"> Total </span></div>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in arusKas.pemasukan" :key="item.category_transaction_id">
                  <td>{{ getIncomeCategory(item.category_transaction_id).category_transaction_name }}</td>
                  <td v-for="n in arusKas.pemasukanMonths.length + (arusKas.pemasukanMonths.length > 1 ? 1 : 0)"
                      :key="n - 1"><span
                      class="has-text-success">{{ $currency(item.TransactionRecord[n - 1]) }}</span></td>
                </tr>

                <tr class="has-background-light">
                  <td class="has-text-weight-bold"
                      :colspan="arusKas.pemasukanMonths.length  + (arusKas.pemasukanMonths.length > 1 ? 1 : 0)"> Total
                  </td>
                  <td><span
                      class="has-text-success has-text-weight-bold">{{ $currency(arusKas.totalPemasukan) }}</span></td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>
          <div v-else>Tidak ada Data</div>
        </div>
        <div class="mb-4">
          <p class="has-text-weight-bold mb-2">Pengeluaran</p>
          <div class="b-table is-size-7" v-if="arusKas.pengeluaran.length">
            <div class="table-wrapper has-mobile-cards">
              <table class="table">
                <thead>
                <tr>
                  <th>
                    <div class="th-wrap"><span class="is-relative"> Kategori </span>
                    </div>
                  </th>
                  <th v-for="(month, key) in arusKas.pengeluaranMonths" :key="key" style="width: 200px;">
                    <div class="th-wrap"><span class="is-relative"> {{ $dateFormat(month, 'MMM YYYY') }} </span></div>
                  </th>
                  <th v-if="arusKas.pengeluaranMonths.length > 1">
                    <div class="th-wrap"><span class="is-relative"> Total </span></div>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in arusKas.pengeluaran" :key="item.category_transaction_id">
                  <td>{{ getExpensesCategory(item.category_transaction_id).category_transaction_name }}</td>
                  <td v-for="n in arusKas.pengeluaranMonths.length + (arusKas.pengeluaranMonths.length > 1 ? 1 : 0)"
                      :key="n - 1"><span
                      class="has-text-danger">{{ $currency(item.TransactionRecord[n - 1]) }}</span></td>
                </tr>

                <tr class="has-background-light">
                  <td class="has-text-weight-bold"
                      :colspan="arusKas.pengeluaranMonths.length  + (arusKas.pengeluaranMonths.length > 1 ? 1 : 0)">
                    Total
                  </td>
                  <td><span
                      class="has-text-danger has-text-weight-bold">{{ $currency(arusKas.totalPengeluaran) }}</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>Tidak ada Data</div>
        </div>

        <div class="columns mt-5">
          <div class="column">
            <div class="is-flex">
              <div>
                <p class="is-size-5 has-text-weight-bold has-text-dark">HPP</p>
                <p class="is-size-7">Harga Pokok Penjualan</p>
              </div>

              <div class="ml-5 is-size-6">
                <div class="is-flex is-align-items-center has-text-centered mb-3">
                  <span class="mr-3">=</span>
                  <div class="is-flex is-flex-direction-column">
                    <span class="border-bottom">Total Pengeluaran Budidaya</span>
                    <span>Total Biomass Panen</span>
                  </div>
                </div>
                <div class="is-flex is-align-items-center has-text-centered mb-3">
                  <span class="mr-3">=</span>
                  <div class="is-flex is-flex-direction-column">
                    <span class="border-bottom">{{ $currency(hpp.total_pengeluaran) }}</span>
                    <span>{{ $numberFormat(hpp.biomass) }} Kg</span>
                  </div>
                </div>
                <div class="is-flex is-align-items-center has-text-centered">
                  <span class="mr-3">=</span>
                  <span class="has-text-weight-bold has-text-dark">{{ $currency(hpp.hpp) }} / Kg</span>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="is-flex">
              <div>
                <p class="is-size-5 has-text-weight-bold has-text-dark">Produktivitas</p>
              </div>

              <div class="ml-5 is-size-6">
                <div class="is-flex is-align-items-center has-text-centered mb-3">
                  <span class="mr-3">=</span>
                  <div class="is-flex is-flex-direction-column">
                    <span class="border-bottom">Total Biomass Panen</span>
                    <span>Total Luas Kolam</span>
                  </div>
                </div>
                <div class="is-flex is-align-items-center has-text-centered mb-3">
                  <span class="mr-3">=</span>
                  <div class="is-flex is-flex-direction-column">
                    <span class="border-bottom">{{ $numberFormat(produktivitas.biomass) }} Kg</span>
                    <span>{{ $numberFormat(produktivitas.total_luas_kolam) }} ha</span>
                  </div>
                </div>
                <div class="is-flex is-align-items-center has-text-centered">
                  <span class="mr-3">=</span>
                  <span class="has-text-weight-bold has-text-dark">{{ $numberFormat(produktivitas.productivitas) }} Kg/ha</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div v-else class="mt-6 pt-6">
      <report-loading :progress="done/totalPromises * 100" :total-promises="totalPromises" :done="done"/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import ReportLoading from "@/components/Farm/Report/ReportLoading";

export default {
  name: "FinanceReport",
  components: {ReportLoading},
  data: () => {
    return {
      total: {},

      incomeOptions: {},
      outcomeOptions: {},

      incomeSeries: [],
      outcomeSeries: [],

      promises: [],
      group_transaction: {
        in: [],
        out: [],
      },

      arusKas: {
        pemasukan: [],
        pengeluaran: [],
        pemasukanMonths: [],
        pengeluaranMonths: [],
      },

      biggestOutcome: '-',
      biggestIncome: '-',

      hpp: {},
      produktivitas: {},

      loaded: false,
      done: 0,
      totalPromises: 0,
    }
  },
  computed: {
    ...mapGetters('finance', [
      'allIncomeCategories',
      'allExpensesCategories'
    ]),

    farm_id() {
      return this.$route.query.farm_id
    },
    farm_name() {
      return this.$route.query.farm_name
    },
    dates() {
      return JSON.parse(this.$route.query.dates)
    },

    from() {
      return this.dates[0]
    },

    to() {
      return this.dates[1] || this.$timestamp(this.$moment().endOf('day'))
    },

    account() {
      return JSON.parse(this.$route.query.account)
    },
  },
  created() {
    document.title = 'SgaraFinance Report - ' + this.account.name + ' - ' + this.farm_name + ' ' + this.$dateFormat(this.from, 'DD/MM/YYYY') + '-' + this.$dateFormat(this.to, 'DD/MM/YYYY')

    this.UPDATE_SELECTED_ACCOUNT(this.account)
    this.loadData()
  },
  methods: {
    doneProgress() {
      ++this.done
    },

    getIncomeCategory(id) {
      return this.allIncomeCategories.find(e => e.category_transaction_id === id)
    },
    getExpensesCategory(id) {
      return this.allExpensesCategories.find(e => e.category_transaction_id === id)
    },

    ...mapMutations('finance', [
      'UPDATE_SELECTED_ACCOUNT'
    ]),

    ...mapActions('finance', [
      'getTotalTransaction',
      'getGroupTransaction',
      'getArusKasByCategory',
      'getHpp',
      'getProduktivitas',
    ]),

    ...mapActions('finance', [
      'getTransactionCategory',
      'getCustomTransactionCategory',
    ]),

    loadData() {
      this.loaded = false

      this.promises.push(new Promise(resolve => {
        this.getTransactionCategory('in').finally(() => {
          this.doneProgress()
          resolve({})
        })
      }))
      this.promises.push(new Promise(resolve => {
        this.getTransactionCategory('out').finally(() => {
          this.doneProgress()
          resolve({})
        })
      }))
      this.promises.push(new Promise(resolve => {
        this.getCustomTransactionCategory('in').finally(() => {
          this.doneProgress()
          resolve({})
        })
      }))
      this.promises.push(new Promise(resolve => {
        this.getCustomTransactionCategory('out').finally(() => {
          this.doneProgress()
          resolve({})
        })
      }))

      this.promises.push(new Promise(resolve => {
        this.getTotalTransaction({
          from: this.from,
          to: this.to,
        }).then(res => {
          if (res && res.data)
            resolve({
              type: 'total',
              data: res.data
            })
          else resolve({})
        }).catch(() => {
          resolve({})
        }).finally(() => {
          this.doneProgress()
        })
      }))
      this.promises.push(new Promise(resolve => {
        this.getGroupTransaction({
          type: 'in',
          from: this.from,
          to: this.to,
        }).then(res => {
          if (res && res.data && Array.isArray(res.data))
            resolve({
              type: 'group-transaction-in',
              data: res.data
            })
          else resolve({})
        }).catch(() => {
          resolve({})
        }).finally(() => {
          this.doneProgress()
        })
      }))
      this.promises.push(new Promise(resolve => {
        this.getGroupTransaction({
          type: 'out',
          from: this.from,
          to: this.to,
        }).then(res => {
          if (res && res.data && Array.isArray(res.data))
            resolve({
              type: 'group-transaction-out',
              data: res.data
            })
          else resolve({})
        }).catch(() => {
          resolve({})
        }).finally(() => {
          this.doneProgress()
        })
      }))
      this.promises.push(new Promise(resolve => {
        this.getArusKasByCategory({
          from: this.from,
          to: this.to,
        }).then(res => {
          if (res && res.data)
            resolve({
              type: 'arus-kas',
              data: res.data
            })
          else resolve({})
        }).catch(() => {
          resolve({})
        }).finally(() => {
          this.doneProgress()
        })
      }))
      this.promises.push(new Promise(resolve => {
        this.getHpp({
          from: this.from,
          to: this.to,
        }).then(res => {
          if (res && res.data)
            resolve({
              type: 'hpp',
              data: res.data
            })
          else resolve({})
        }).catch(() => {
          resolve({})
        }).finally(() => {
          this.doneProgress()
        })
      }))
      this.promises.push(new Promise(resolve => {
        this.getProduktivitas({
          from: this.from,
          to: this.to,
        }).then(res => {
          if (res && res.data)
            resolve({
              type: 'produktivitas',
              data: res.data
            })
          else resolve({})
        }).catch(() => {
          resolve({})
        }).finally(() => {
          this.doneProgress()
        })
      }))

      this.totalPromises = this.promises.length

      Promise.all(this.promises).then(res => {
        let total = res.find(e => e.type === 'total')

        if (total) {
          this.total = total.data
          this.total.cashflow = total.data.total_pemasukan - total.data.total_pengeluaran
        }

        let group_transaction_in = res.find(e => e.type === 'group-transaction-in')
        if (group_transaction_in && group_transaction_in.data) {
          this.group_transaction.in = group_transaction_in.data
          this.incomeOptions = {
            chart: {
              type: 'donut',
              animations: {
                enabled: false,
              },
            },
            labels: group_transaction_in.data.map(e => e.category_transaction_name),
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 300
                },
              }
            }],
            legend: {
              show: true,
              position: 'bottom'
            },
            dataLabels: {
              enabled: true,
              enabledOnSeries: undefined,
              distributed: false,
              offsetX: 0,
              offsetY: 0,
              style: {
                fontSize: '14px',
                fontFamily: 'Nunito Sans, Arial, sans-serif',
                fontWeight: 'bold',
                colors: ['#000', '#000', '#000', '#000', '#000', '#000', '#000']
              },
              dropShadow: {
                enabled: false,
              }
            },

            plotOptions: {
              pie: {
                startAngle: 0,
                expandOnClick: true,
                offsetX: 0,
                offsetY: 0,
                customScale: .8,
                dataLabels: {
                  offset: 40,
                  minAngleToShowLabel: 0
                },
                donut: {
                  size: '70%',
                  background: 'transparent',
                },
              }
            },
          }
          this.incomeSeries = group_transaction_in.data.map(e => e.nominal)
          this.biggestIncome = group_transaction_in.data.sort((a, b) => b.nominal > a.nominal ? 1 : -1)[0].category_transaction_name
          this.group_transaction.in.push({
            category_transaction_name: 'Total',
            nominal: this.group_transaction.in.reduce((a, b) => a + b.nominal, 0)
          })
        }

        let group_transaction_out = res.find(e => e.type === 'group-transaction-out')

        if (group_transaction_out) {
          this.group_transaction.out = group_transaction_out.data
          this.outcomeOptions = {
            chart: {
              type: 'donut',
              animations: {
                enabled: false,
              },
            },
            labels: group_transaction_out.data.map(e => e.category_transaction_name),
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 300
                },
              }
            }],
            legend: {
              show: true,
              position: 'bottom'
            },
            dataLabels: {
              enabled: true,
              enabledOnSeries: undefined,
              distributed: false,
              offsetX: 0,
              offsetY: 0,
              style: {
                fontSize: '14px',
                fontFamily: 'Nunito Sans, Arial, sans-serif',
                fontWeight: 'bold',
                colors: ['#000', '#000', '#000', '#000', '#000', '#000', '#000']
              },
              dropShadow: {
                enabled: false,
              }
            },

            plotOptions: {
              pie: {
                startAngle: 0,
                expandOnClick: true,
                offsetX: 0,
                offsetY: 0,
                customScale: .8,
                dataLabels: {
                  offset: 40,
                  minAngleToShowLabel: 0
                },
                donut: {
                  size: '70%',
                  background: 'transparent',
                },
              }
            },
          }
          this.outcomeSeries = group_transaction_out.data.map(e => e.nominal)
          this.biggestOutcome = group_transaction_out.data.sort((a, b) => b.nominal > a.nominal ? 1 : -1)[0].category_transaction_name
          this.group_transaction.out.push({
            category_transaction_name: 'Total',
            nominal: this.group_transaction.out.reduce((a, b) => a + b.nominal, 0)
          })
        }

        let arusKas = res.find(e => e.type === 'arus-kas')

        if (arusKas && arusKas.data.pemasukan && arusKas.data.pengeluaran) {
          let pemasukanMonths = arusKas.data.pemasukan.reduce((a, b) => {
            for (let i = 0; i < b.TransactionRecord.length; i++) {
              let transaction = b.TransactionRecord[i]
              let timestamp = this.$timestamp(this.$moment(transaction.transaction_date).startOf('month'))

              let find = a.find(e => e === timestamp)
              if (!find) a.push(timestamp)
            }

            return a
          }, [])
          let pengeluaranMonths = arusKas.data.pengeluaran.reduce((a, b) => {
            for (let i = 0; i < b.TransactionRecord.length; i++) {
              let transaction = b.TransactionRecord[i]
              let timestamp = this.$timestamp(this.$moment(transaction.transaction_date).startOf('month'))

              let find = a.find(e => e === timestamp)
              if (!find) a.push(timestamp)
            }

            return a
          }, [])
          let totalPemasukan = 0
          arusKas.data.pemasukan = arusKas.data.pemasukan.map(e => {
            let total = 0
            e.TransactionRecord = e.TransactionRecord.reduce((a, b) => {
              let index = pemasukanMonths.indexOf(this.$timestamp(this.$moment(b.transaction_date).startOf('month')))
              if (!a[index]) a[index] = b.nominal
              else a[index] += b.nominal
              total += b.nominal
              return a
            }, [])
            e.TransactionRecord[pemasukanMonths.length] = total
            totalPemasukan += total
            return e
          })
          arusKas.data.totalPemasukan = totalPemasukan

          let totalPengeluaran = 0
          arusKas.data.pengeluaran = arusKas.data.pengeluaran.map(e => {
            let total = 0
            e.TransactionRecord = e.TransactionRecord.reduce((a, b) => {
              let index = pengeluaranMonths.indexOf(this.$timestamp(this.$moment(b.transaction_date).startOf('month')))
              if (!a[index]) a[index] = b.nominal
              else a[index] += b.nominal
              total += b.nominal
              return a
            }, [])
            e.TransactionRecord[pengeluaranMonths.length] = total
            totalPengeluaran += total
            return e
          })
          arusKas.data.totalPengeluaran = totalPengeluaran

          this.arusKas = {
            ...arusKas.data,
            pemasukanMonths,
            pengeluaranMonths,
          }
        }

        let hpp = res.find(e => e.type === 'hpp')

        if (hpp) {
          this.hpp = hpp.data
        }

        let produktivitas = res.find(e => e.type === 'produktivitas')

        if (produktivitas) {
          this.produktivitas = produktivitas.data
        }

        this.loaded = true
      })
    },
    print() {
      let generate = this.$refs.print
      generate.style.display = 'none'
      let helpBtn = document.querySelector('.help-btn')
      let hidePrint = document.querySelectorAll('.hide-print')
      for (let i = 0; i < hidePrint.length; i++) {
        hidePrint[i].style.display = 'none'
      }

      helpBtn.style.display = 'none'

      window.print()

      generate.style.display = 'block'
      helpBtn.style.display = ''
      for (let i = 0; i < hidePrint.length; i++) {
        hidePrint[i].style.display = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 1000px;
}

.print-overlay {
  margin-bottom: 3rem;
}

.report {
  margin-bottom: 5rem;
}
</style>

<style lang="scss">

svg, .avoid-break {
  page-break-inside: avoid;
  break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
}

@page {
  size: A4;
}

@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }

  * {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
  }

  svg, .avoid-break {
    page-break-inside: avoid;
    break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
  }
}

.border-bottom {
  border-color: #7a7a7a;
}
</style>