<template>
  <div class="has-text-centered">
    <div class="mb-2">
      <img src="/img/assets/sgara-logo.png" class="d-inline-block align-top" alt="Sgara Logo" style="height: 48px">
    </div>
    <b-progress type="is-info" v-model="progress" show-value format="percent" class="mt-5"></b-progress>
    <div>
      <p>Generating Report</p>
      <p class="is-size-7">[{{ done }}/{{ totalPromises }}]</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportLoading",
  props: {
    progress: Number,
    done: Number,
    totalPromises: Number,
  }
}
</script>
